import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordForm } from '@interfaces/password-form.interface';
import { propertyTypeValidator } from '@modules/controls/validators/property-form.validator';
import { PasswordValidators } from '@utils/password-validator';

@Injectable()
export class UserFormService {
  form = this.createForm();

  reset() {
    this.form = this.createForm();
  }

  private createForm() {
    return new FormGroup({
      name: new FormControl<string>('', { nonNullable: true, validators: [Validators.required] }),
      last_name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
      email: new FormControl<string>(
        { value: '', disabled: true },
        { nonNullable: true, validators: [Validators.required] },
      ),
      password: new FormControl('', {
        nonNullable: true,
        validators: [PasswordValidators.passwordRequired],
      }),
      passwordForm: new FormGroup<PasswordForm>(
        {
          newPassword: new FormControl('', {
            nonNullable: true,
            validators: [PasswordValidators.validatePasswordField],
          }),
          confirmPassword: new FormControl('', {
            validators: [PasswordValidators.passwordMatch],
          }),
        },
        {
          validators: [
            PasswordValidators.passwordCharactersLength(8),
            PasswordValidators.passwordCapitalLetter,
            PasswordValidators.passwordLowercaseLetter,
            PasswordValidators.passwordSymbol,
            PasswordValidators.passwordNumbers,
          ],
          updateOn: 'change',
        },
      ),
      role: new FormControl<{ name: string }>({ value: {}, disabled: true } as any, {
        nonNullable: true,
        validators: [propertyTypeValidator],
      }),
      locale: new FormControl<{ name: string; locale: string }>({} as any, {
        nonNullable: true,
        validators: [propertyTypeValidator],
      }),
    });
  }
}
