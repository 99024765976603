import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RegExpList } from './reg-exp';

export function hasErrors(control: AbstractControl): boolean {
  return !!Object.keys(control.errors || {}).length;
}

export namespace PasswordValidators {
  export const passwordMatch = (control: AbstractControl): ValidationErrors | null => {
    control.setErrors(null);

    const passwordValue = control.parent?.get('newPassword')?.value;
    if (!control.value?.length) return null;
    if (passwordValue && control.value !== passwordValue) {
      return { passwordNotMatch: true };
    }

    return null;
  };

  export const validatePasswordField = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const confirmPassword = control.parent?.get('confirmPassword');
    const matches = (regex: RegExp) => control?.value?.match(regex);
    const valid = [
      matches(RegExpList.allowedCharacters),
      matches(RegExpList.capitalLetter),
      matches(RegExpList.lowercaseLetter),
      matches(RegExpList.allowedSymbol),
      matches(RegExpList.numbers),
    ].every(Boolean);

    if (confirmPassword && control.value === confirmPassword.value) {
      confirmPassword.setErrors(null);
    } else if (confirmPassword?.value?.length && control.value !== confirmPassword.value) {
      confirmPassword.setErrors({ passwordNotMatch: true });
    }

    return !valid ? { password: true } : null;
  };

  export const passwordCharactersLength =
    (length: number): ValidatorFn =>
    (group: AbstractControl): null | ValidationErrors => {
      const passwordControl = group.get('newPassword');
      const isValid = passwordControl?.value?.length >= length;

      if (passwordControl && isValid) {
        return null;
      } else {
        return { characters: true };
      }
    };

  export const passwordCapitalLetter = (group: AbstractControl): null | ValidationErrors => {
    const passwordControl = group.get('newPassword');
    const isValid = passwordControl?.value?.match(RegExpList.capitalLetter);

    if (passwordControl && isValid) {
      return null;
    } else {
      return { capitalLetter: true };
    }
  };

  export const passwordLowercaseLetter = (group: AbstractControl): null | ValidationErrors => {
    const passwordControl = group.get('newPassword');
    const isValid = passwordControl?.value?.match(RegExpList.lowercaseLetter);

    if (passwordControl && isValid) {
      return null;
    } else {
      return { lowercaseLetter: true };
    }
  };

  export const passwordSymbol = (group: AbstractControl): null | ValidationErrors => {
    const passwordControl = group.get('newPassword');

    const isValid = passwordControl?.value?.match(RegExpList.allowedSymbol);

    if (passwordControl && isValid) {
      return null;
    } else {
      return { symbol: true };
    }
  };

  export const passwordNumbers = (group: AbstractControl): null | ValidationErrors => {
    const passwordControl = group.get('newPassword');
    const isValid = passwordControl?.value?.match(RegExpList.numbers);

    if (passwordControl && isValid) {
      return null;
    } else {
      return { numbers: true };
    }
  };

  export const passwordRequired = (formControl: AbstractControl): ValidationErrors | null => {
    const validator = Validators.required;

    if (!validator(formControl)) {
      return null;
    } else {
      return { passwordRequired: true };
    }
  };
}
