import { InsightTypeEnum } from '@enums/insight-type.enum';
import { InsightIcon, InsightItemType } from '@type/insight.type';

export const getIconNameFromInsightType = ({ insight_type }: InsightItemType): InsightIcon => {
  switch (insight_type) {
    case InsightTypeEnum.OutOfStock:
      return 'out-of-stock';
    case InsightTypeEnum.Expired:
      return 'expired';
    case InsightTypeEnum.SetNext:
      return 'next-status';
    case InsightTypeEnum.ExpiredDefaultStatus:
      return 'listing';

    default:
      return 'pending-deletion';
  }
};

export const getInsightIconFn = (): ((insight: InsightItemType) => InsightIcon) => {
  return (insight: InsightItemType) => {
    const iconName: InsightIcon = getIconNameFromInsightType(insight);
    return iconName;
  };
};
